const MESSAGE_STRINGS = {
  SAVE: 'Save',
  CANCEL: 'Cancel',
  UPDATE: 'Update',
  TOAST_SUCCESS_MESSAGE: 'Changes have been saved successfully!',
  TOAST_ERROR_MESSAGE: 'Something went wrong. Please try again later!',
  YP_ENABLE_TOOLTIP:
    'Please contact your system admin before enabling this feature.',
  LOADING_INDICATOR: 'Loading...',
};

export default MESSAGE_STRINGS;
